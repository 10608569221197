<style lang="scss">
.audio-video-all {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .collapse-content-link {
      display: inline-block;
      font-weight: 500;
      color: $blue-darker;
    }
  }
  .collapse-content-link-v2 {
    background: none;
    padding: 0;
    display: inline-block;
    font-weight: 500;
    color: $blue-darker;
    border: 0;
    outline: none;
  }

  img {
    margin: 15px auto;
    width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    display: inline-block;
    padding: 15px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff !important;
    }
  }

  .rating {
    display: none;
    margin: 0;
    border-top: 2px solid $blue-darker;
    padding-top: 20px;
    padding-bottom: 20px;
    .header {
      margin-bottom: 10px;
      @media (max-width: 768px) {
        text-align: center;
      }
      strong {
        background: $blue-darker;
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 130%;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        @media (max-width: 992px) {
          margin-left: 0;
          display: block;
          margin-top: 5px;
        }
      }
    }
    .col-md-4 {
      text-align: center;
      border-right: 1px solid #ccc;
      @media (max-width: 992px) {
        border-right: 0;
      }
      .rating-content {
        .name {
          margin-top: 10px;
          margin-bottom: 5px;
        }
      }
    }
    .border-right-none {
      border-right: 0;
    }
  }
  .tabelle-box {
    strong {
      padding-left: 0 !important;
    }
    .row {
      [class*='col-'] {
        //border:1px solid red;
        align-self: center;
      }
      small {
        display: block;
      }
    }
  }
  .podcast-examples {
    img {
      max-width: 150px;
      margin: 15px 0;
    }
  }
}
.collapsible-container {
}
</style>

<template>
  <HighlightBox />
  <div class="content audio-video-all container padding-b-m">
    <h1>Werbung für Augen und Ohren</h1>
    <p class="intro-txt">
      Kaufentscheidungen werden vor allem auf emotionaler Basis getroffen. Umso besser, wenn Werbung auf Emotion trifft – und genau das ist die Stärke von Kommunikationslösungen im Bereich Audio & Video. Mit den innovativen Möglichkeiten der auditiven und visuellen Kommunikation auf den Plattformen der Kleinen Zeitung stärken Sie Marke in Ihrer Zielgruppe. Denn emotionale Botschaften bleiben
      länger im Gedächtnis und in den Köpfen Ihrer Kunden verankert.
    </p>

    <div class="margin-t-m"></div>
    <h2>Ihre Werbemöglichkeiten</h2>
    <TabGroup>
      <Tab tabid="displaywerbung-tab1" :title="'Podcast '" active>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h2>Kino im Kopf</h2>
            <p>Immer mehr Menschen entdecken die Podcast-Welt für sich – der kleine, feine Ohrenschmaus, der <strong>unabhängig von Zeit und Ort</strong> genossen werden kann. Mit den vielfältigen <strong>Podcast-Formaten der Kleinen Zeitung</strong> bleiben Sie mit Ihrer Werbebotschaft in den Köpfen der Hörerinnen und Hörer.</p>
            <br />
            <h2>Ihre Vorteile</h2>
            <ul class="content-ul">
              <li>Podcasts sind <strong>ortsunabhängig zu jeder Tageszeit abrufbar</strong>. Die ununterbrochene Abrufbarkeit macht den Podcast zu einem <strong>optimalen, benutzerfreundlichen Format</strong>.</li>
              <li>Mithilfe Ihrer Markenpräsenz in den Kleine-Zeitung-Podcasts haben Sie die <strong>ungeteilte Aufmerksamkeit von Hörerinnen und Hörer</strong> und stärken Ihren Bekanntheitsgrad als Unternehmen.</li>
              <li>Im Podcast-Universum der Kleinen Zeitung finden sich die <strong>unterschiedlichsten Themenbereiche</strong>, in denen sich Ihre Werbebotschaft ideal einbetten lässt – ein innovativer und spannender Auftritt.</li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center margin-tb-s podcast-examples">
          <div class="col-md-6 padding-tb">
            <div class="row justify-content-center">
              <div class="col-lg-4 align-self-center">
                <img src="@/assets/img/img-content/digital/Eiskalt.png" alt="Eiskalt" class="img-fluid" />
              </div>
              <div class="col-lg-8 align-self-center">
                <p><strong>Eiskalt – Der Sportpodcast</strong><br />jede Woche eine neue Ausgabe, ca. 600 Hörerinnen und Hörer pro Folge</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 align-self-center">
                <img src="@/assets/img/img-content/digital/Fair-Female.png" alt="Fair und Female" class="img-fluid" />
              </div>
              <div class="col-lg-8 align-self-center">
                <p><strong>Fair &amp; female – Der Gesellschaftspodcast</strong><br />jede Woche eine neue Ausgabe, ca. 1.000 Hörerinnen und Hörer pro Folge</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 padding-tb">
            <div class="row justify-content-center">
              <div class="col-lg-4 align-self-center">
                <img src="@/assets/img/img-content/digital/Delikt.png" alt="Delikt – Der Kriminalpodcast" class="img-fluid" />
              </div>
              <div class="col-lg-8 align-self-center">
                <p><strong>Delikt – Der Kriminalpodcast</strong><br />jede Woche eine neue Ausgabe, ca. 3.000 Hörerinnen und Hörer pro Folge</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 align-self-center">
                <img src="@/assets/img/img-content/digital/Gesund.png" alt="Gesund" class="img-fluid" />
              </div>
              <div class="col-lg-8 align-self-center">
                <p><strong>Ist das gesund? – Der Medizinpodcast</strong><br />alle 14 Tage eine neue Ausgabe, ca. 2.000 Hörerinnen und Hörer pro Folge</p>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="info-txt-tabelle">Durchschnittlicher Leistungszeitraum 4 bis 6 Wochen pro Folge.</div>
          </div>
        </div>
        <div class="row justify-content-center margin-tb-s">
          <div class="col-lg-12 justify-content-center align-self-center padding-tb-s">
            <h2>Leistungen &amp; Tarif</h2>
            <div class="tabelle-box">
              <div class="row head-row hide-small">
                <div class="col-lg-2 text-align-left">LEISTUNGEN</div>
                <div class="col-lg-5 text-align-center">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 text-align-center">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-2 col-6 text-align-left text-center-small">Eiskalt</div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">900,00 <small>Mindeslaufzeit: 3 Monate</small></div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">1800,00 <small>Mindeslaufzeit: 6 Monate</small></div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-2 col-6 text-align-left text-center-small">Fair &amp; Female</div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">750,00 <small>Mindeslaufzeit: 3 Monate</small></div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">1500,00 <small>Mindeslaufzeit: 6 Monate</small></div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-2 col-6 text-align-left text-center-small">Delikt</div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">2.250,00 <small>Mindeslaufzeit: 6 Monate</small></div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">4.500,00 <small>Mindeslaufzeit: 12 Monate</small></div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-2 col-6 text-align-left text-center-small">Ist das gesund?</div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">3.000,00 <small>Mindeslaufzeit: 4,5 Monate</small></div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">6.000,00 <small>Mindeslaufzeit: 9 Monate</small></div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-2 col-6 text-align-left text-center-small"><strong class="font-color-red">Must-have:</strong> <br />Presenter (Producer Read)</div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET SMALL (5 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">max. 45 Sekunden <small>inklusive Sprecher und Musik</small></div>
                <div class="col-6 text-align-left visible-small">PRESENTER-PAKET LARGE (10 FOLGEN)</div>
                <div class="col-lg-5 col-6 text-align-center">400,00 <small>Produktionskosten</small></div>
              </div>
            </div>

            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
          </div>
        </div>

        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="../../pdf/digital/Kleine-Zeitung-Podcast.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Podcast
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>153 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
      <Tab tabid="displaywerbung-tab2" :title="'Video-Ads'">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h2>Ihr Auftritt, bitte!</h2>
            <p>Bewegte Bilder sind ein Eckpfeiler auf kleinezeitung.at – und dieses Videoumfeld können Sie für Ihre Werbebotschaft nutzen. Denn mit den vielfältigen Möglichkeiten der <strong>Video-Ad-Einbindung</strong> bleiben Sie <strong>langfristig in Erinnerung</strong> und stärken Ihre Marke und Ihre Message.</p>
            <br />
          </div>
        </div>
        <!-- -->
        <div class="row justify-content-center">
          <div class="col-lg-6 align-self-center">
            <h2>Ihre Vorteile</h2>
            <ul class="content-ul">
              <li><strong>Themenaffin:</strong> Videoumfelder, die genau Ihre Zielgruppe erreichen.</li>
              <li><strong>Aufmerksamkeitsstark:</strong> Videos bleiben langfristig im Gedächtnis.</li>
              <li><strong>Emotional:</strong> Videos berühren und wecken Emotionen.</li>
            </ul>
          </div>
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content/digital/Video-Ad.jpg" alt="Video-Ad" class="img-fluid img-center img-s-75 img-center padding-tb-s" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 align-self-center">
            <h2>Leistungen &amp; Tarif</h2>

            <div class="tabelle-box">
              <div class="row head-row hide-small">
                <div class="col-lg-3 text-align-left">LEISTUNGEN</div>
                <div class="col-lg-3 text-align-left">BESCHREIBUNG</div>
                <div class="col-lg-3 text-align-center">PLATZIERUNG</div>
                <div class="col-lg-3 text-align-center">TARIF<sup>1</sup></div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Opener</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Presenter vor dem Video<br />Dauer: 5–7 Sekunden</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Instream</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">TKP 30,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Closer</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Presenter nach dem Video<br />Dauer: 5–7 Sekunden</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Instream</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">TKP 30,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Video-Teaser</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Logo-Präsenz im Zuge einer Native-Ad-Bewerbung eines Videobeitrags</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Website</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">TKP 10,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Sponsorbalken</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Sponsorbalken auf Banner/Billboardplatz in der Mediathek im jeweiligen Themenumfeld</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Mediathek</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">TKP 19,50</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Product Placement</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Produkt/Logo wird am Drehort ins Bild gerückt (Screens)</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Videobeitrag</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">TKP 2,50 pro Minute</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Social-Teaser</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Bezahlter Post auf Social-Media, welcher auf den Kanälen verschiedenster Themenumfelder ausgespielt wird</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center">Facebook-Post (800 Klicks)</div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">1.220,00</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">LEISTUNGEN</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small"><strong class="font-color-red">Zusätzlich buchbar:</strong><br />Presenter</div>
                <div class="col-6 text-align-left visible-small">BESCHREIBUNG</div>
                <div class="col-lg-3 col-6 text-align-left text-center-small">Produktion eines Presenters (Opener/Closer) <br />Dauer: je 5–7 Sekunden; Umfang: Bild, Text, Logo, Sprecher</div>
                <div class="col-6 text-align-left visible-small">PLATZIERUNG</div>
                <div class="col-lg-3 col-6 text-align-center"></div>
                <div class="col-6 text-align-left visible-small">TARIF<sup>1</sup></div>
                <div class="col-lg-3 col-6 text-align-center">552,50</div>
              </div>
              <div class="info-txt-tabelle"><sup>1 </sup>TKPs sind abhängig von Buchungs- bzw. Teasingvolumen. Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
            </div>
            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
          </div>
        </div>
        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="../../pdf/digital/Kleine-Zeitung-Video-Ads.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Video-Ads
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>215 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import HighlightBox from '@/components/HighlightBox.vue';

export default defineComponent({
  components: {
    TabGroup,
    Tab,
    HighlightBox,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
    };
  },
});
</script>
